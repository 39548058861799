export const environment = {
  name: 'development',
  production: false,
  minLengthShortText: 3,
  maxLengthShortText: 10,
  minLengthLongText: 5,
  maxLengthLongText: 15,
  recaptchaSiteKey: 'RECAPTCHA',
  PUBLIC_API: 'https://dev.app-user.applyze.com',
  API_KEY: 'ARz1Er9DHR0juxtDLHcywIGWVe86m7UzkLhpUeRMVtY=',
  SHOPIROLLER_API: 'https://dev.ecommerce.applyze.com',
  version: 'v2.0.3.0-pre-dev'
};
